import React from "react";

const UsersConfigs = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/apps/users",
            component: React.lazy(() => import("./PageUsers")),
            auth: ['Admin'],
        },
    ],
};

export default UsersConfigs;