import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import { BACKEND_URL, SECRET_KEY } from "config";
import jwtDecode from "jwt-decode";
import CryptoJS from 'crypto-js';


class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(err) => {
				return new Promise((resolve, reject) => {
					if (
						err.response.status === 401 &&
						err.config &&
						!err.config.__isRetryRequest
					) {
						// if you ever get an unauthorized response, logout the user
						// this.emit("onAutoLogout", "Invalid access_token");
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit("onNoAccessToken");
			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit("onAutoLogin", true);
		} else {
			// this.setSession(null);
			this.emit("onAutoLogout", "access_token expired");
		}
	};

	signInWithEmailAndPassword = (formData) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${BACKEND_URL}/api/user/login/`, formData)
				.then((response) => {
					// console.log("response", response);

					if (response?.data?.status === 200) {
						this.setSession(response?.data?.token);
						const temp = response?.data?.data;
						const userData = {
							data: {
								date_joined: temp?.date_joined,
								email: temp.email,
								id: temp.id,
								username: temp.username,
								displayName: temp.username,
								photoURL: "",
								last_name: temp.last_name,
								first_name: temp.first_name,
								email: temp.email,
								mobile_number: temp.mobile_number,
								accounts: temp.accounts,
								locations: temp.locations
							},
							role: temp.rol,
							uuid: temp.id

						};
						// console.log("response111 ::", userData);

						const current_time = new Date().getTime();
						const expired_time = current_time + 60000;
						localStorage.setItem("expired_time", expired_time);
						localStorage.setItem("cart_arr", JSON.stringify([]));
						localStorage.setItem("timeStemp", Math.floor(Date.now() / 1000));
						// console.log("response ::", response);

						// localStorage.setItem("current_user", JSON.stringify(userData));
						localStorage.setItem("current_user", this.encryptStr(JSON.stringify(userData)));

						// const encryptedText = this.encryptStr(JSON.stringify(userData));
						// console.log('Encrypted:', encryptedText);

						// const decryptedText = this.decryptStr(encryptedText);
						// console.log('Decrypted:', decryptedText);

						resolve(userData);
					}
				})
				.catch((error) => {
					// console.log("response111 ::", error);
					if (!error.response) {
						reject(
							"Please check your internet connection , Server are temporary down or API connection failed !"
						);
					} else {
						// console.log("response111 ::", error.response);
						if (error?.response?.status === 401) {
							reject("Invalid email and password!");
						} else {
							reject(
								error?.response?.data?.status_message || "Something went wrong!"
							);
						}
					}
				});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			// alert("signInWithToken");
			// console.log(window.localStorage.getItem('current_user'));
			// console.log("window.localStorage.getItem('jwt_access_token')\n",window.localStorage.getItem('jwt_access_token'));

			// console.log("tt \n ", temp, "\n", typeof (temp));
			// console.log("temp.user",temp.user);

			if (
				window.localStorage.getItem("jwt_access_token") &&
				window.localStorage.getItem("current_user")
			) {
				// let temp = window.localStorage.getItem("current_user");
				let temp = this.decryptStr();

				// console.log("tt \n ", temp, "\n", typeof (temp));
				// temp = JSON.parse(temp);
				this.setSession(window.localStorage.getItem("jwt_access_token"));
				resolve(temp);
			} else {
				this.logout();
				reject(new Error("Failed to login with token."));
			}
		});
	};


	getAllUsers = async (params) => {
		const queryString = new URLSearchParams(params).toString();
		try {
			const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/user/?${queryString}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				}
			});

			// Verifica si la respuesta tiene el estado 401
			if (response.status === 401) {
				this.emit("onAutoLogout", "access_token expired");
				throw new Error('Unauthorized access - 401');

			}

			// Verifica si la respuesta tiene otros errores
			if (!response.ok) {
				throw new Error('Error fetching users');
			}

			// Si todo está bien, devuelve los datos en formato JSON
			return await response.json();
		} catch (error) {
			// Verifica si el error es específicamente de autorización (401)
			if (error.message.includes('401')) {
				console.error('Error: Unauthorized - Token may have expired or is invalid.');
				// Puedes manejar aquí la lógica para renovar el token o redirigir al usuario al login
			} else {
				console.error('Error fetching users:', error);
			}
		}
	};


	updateUser = async (userId, updatedData) => {
		try {
			const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/user/${userId}/`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				},
				body: JSON.stringify(updatedData),
			});
			if (!response.ok) {
				throw new Error(response.error);
			}
			const data = await response.json();
			// Aquí puedes manejar la respuesta del servidor si es necesario

		} catch (error) {
			// console.error('Error updating user:', error);
			return { "error": response.error };
		}
	};

	createLocation = async (locationData) => {
		try {
			const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/location/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				},
				body: JSON.stringify(locationData),
			});

			if (!response.ok) {
				let errorMessage = 'Error updating user';
				const errorData = await response.json();
				if (errorData && errorData.errors) {
					errorMessage = errorData.errors;
				}
				console.log(errorData.errors)
				console.log(errorMessage)
				throw new Error(errorMessage);
			}

			return await response.json();

		} catch (error) {
			// Captura y maneja cualquier error que ocurra durante la solicitud
			console.error('Error updating user:', error);
			// Devuelve null o algún otro valor para indicar que la solicitud falló
			return { "error": error };
		}
	};

	getLocation = async (params) => {
		const access_token = localStorage.getItem("jwt_access_token");

		const queryString = new URLSearchParams(params).toString();
		try {

			const response = await fetch(`${BACKEND_URL}/api/location/?${queryString}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				}
			});

			if (!response.ok) {
				let errorMessage = 'Error updating user';
				const errorData = await response.json();
			
				if (response.status === 401) {
					this.emit("onAutoLogout", "access_token expired");
					throw new Error('Unauthorized access - 401');

				}
				if (errorData && errorData.errors) {
					errorMessage = errorData.errors;
				}
				console.log(errorData.errors)
				console.log(errorMessage)
				throw new Error(errorMessage);
			}

			return await response.json();

		} catch (error) {
			// Captura y maneja cualquier error que ocurra durante la solicitud
			console.error('Error updating user:', error);
			// Devuelve null o algún otro valor para indicar que la solicitud falló
			return { "error": error };
		}
	};



	createBankAccount = async (accountData) => {
		try {
			const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/user/${accountData.id}/add_account_user/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				},
				body: JSON.stringify(accountData),
			});

			// Verifica si la respuesta no es exitosa
			if (!response.ok) {
				// Intenta parsear el cuerpo de la respuesta como JSON
				const errorData = await response.json();
				// Lanza un nuevo error con el mensaje de error devuelto por el backend
				throw new Error(JSON.stringify({ status: response.status, message: errorData.message }));
			}

			// Si la respuesta es exitosa, devuelve los datos de la ubicación
			return await response.json();
		} catch (error) {
			// Captura y maneja cualquier error que ocurra durante la solicitud
			console.error('Error in createBankAccount:', error.message, 'for account:', accountData);
			// Devuelve un objeto con detalles del error
			return { "error": error };
		}
	};

	updateBankAccount = async (accountId, accounData) => {
		try {
			const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/user/${accountId}/update_account_user/`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				},
				body: JSON.stringify(accounData),
			});
			pi_3QR25ZAoM5uvXad31v6rmJoO
			// Verifica si la respuesta no es exitosa
			if (!response.ok) {
				// Intenta parsear el cuerpo de la respuesta como JSON
				const errorData = await response.json();
				// Lanza un nuevo error con el mensaje de error devuelto por el backend
				throw new Error(errorData.message || 'Error updating user');
			}

			// Si la respuesta es exitosa, devuelve los datos de la ubicación
			return await response.json();
		} catch (error) {
			// Captura y maneja cualquier error que ocurra durante la solicitud
			console.error('Error updating user:', error);
			// Devuelve null o algún otro valor para indicar que la solicitud falló
			return { "error": error };
		}
	};

	createPrinter = async (printerData) => {
		try {
			const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/device/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${access_token}`,
				},
				body: JSON.stringify(printerData),
			});

			// Verifica si la respuesta no es exitosa
			if (!response.ok) {
				// Intenta parsear el cuerpo de la respuesta como JSON
				const errorData = await response.json();
				// Lanza un nuevo error con el mensaje de error devuelto por el backend
				throw new Error(JSON.stringify({ status: response.status, message: errorData.message }));
			}

			// Si la respuesta es exitosa, devuelve los datos de la ubicación
			return await response.json();
		} catch (error) {
			// Captura y maneja cualquier error que ocurra durante la solicitud
			console.error('Error in Printer:', error.message, 'for printer:', printerData);
			// Devuelve un objeto con detalles del error
			return { "error": error };
		}
	};

	userDataOfLocalStorege = async (action, userData = null) => {
		if (action === "get") {
			// return await JSON.parse(localStorage.getItem("current_user"))
			return await this.decryptStr();

		}
		else if (action == "post") {
			// const userDataOriginal = JSON.parse(localStorage.getItem("current_user"))
			const userDataOriginal = this.decryptStr();

			for (let key in userData) {
				if (userDataOriginal.data.hasOwnProperty(key)) {
					userDataOriginal.data[key] = userData[key];
				}
			}

			return localStorage.setItem("current_user", this.encryptStr(JSON.stringify(userDataOriginal)));
		}
	}

	verifyBankAccount = async (bankAccountData) => {
		try {
			// const access_token = localStorage.getItem("jwt_access_token");
			const response = await fetch(`${BACKEND_URL}/api/user/bank_account_verified/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(bankAccountData),
			});

			// Verifica si la respuesta no es exitosa
			if (!response.ok) {
				// Intenta parsear el cuerpo de la respuesta como JSON
				const errorData = await response.json();
				// Lanza un nuevo error con el mensaje de error devuelto por el backend
				throw new Error(errorData.message || 'Error updating user');
			}

			// Si la respuesta es exitosa, devuelve los datos de la ubicación
			return await response.json();
		} catch (error) {
			// Captura y maneja cualquier error que ocurra durante la solicitud
			console.error('Error updating user:', error);
			// Devuelve null o algún otro valor para indicar que la solicitud falló
			return { "error": error };
		}
	}

	setSession = (access_token) => {
		if (access_token) {
			localStorage.setItem("jwt_access_token", access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem("jwt_access_token");
			delete axios.defaults.headers.common.Authorization;
			localStorage.clear();
		}
	};

	logout = () => {
		console.warn("logout");
		this.setSession(null);
		localStorage.clear();
	};

	isAuthTokenValid = (access_token) => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn("access token expired");
			this.logout()
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem("jwt_access_token");
	};

	encryptStr(plainStr) {
		const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
		const iv = CryptoJS.lib.WordArray.random(16);
		const encrypted = CryptoJS.AES.encrypt(plainStr, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
		const encryptedStr = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
		return encryptedStr;
	}

	decryptStr() {
		const encryptedStr = localStorage.getItem("current_user")
		const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);

		const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedStr);
		const iv = CryptoJS.lib.WordArray.create(encryptedWordArray.words.slice(0, 4));
		const ciphertext = CryptoJS.lib.WordArray.create(encryptedWordArray.words.slice(4), encryptedWordArray.sigBytes - 16);

		const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
		const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);

		return JSON.parse(decryptedStr);
	}

}

const instance = new JwtService();

export default instance;
